import PandoraModel from "traits/PandoraModel";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import VisibilityIcon from '@material-ui/icons/Visibility';
import * as License from "license.js";
import MenuItem from "./MenuItem";

class Menu extends PandoraModel {
	static modelName = "Menu";
	static getLicense() { return License.ICECASH}

	static icon = MenuBookIcon;

	// PANDORA_ANCHOR_INSERT_OVERRIDE_VARIABLES_HERE
	static permissionNamesAreSingular = true;
	static orderable = true;
	static copyable = true;

	static cards = [
		{
			title: "GENERAL_INFO",
			icon: MenuBookIcon,
			fields: {
				// PANDORA_ANCHOR_INSERT_FIELDS_HERE
				name: {type: "text", required: true, showOnCopy: true, showOnTable: true, },
			},
		},
		{
			title: "PUBLIC_INFO",
			icon: VisibilityIcon,
			fields: {
				publicly_visible: {type: "checkbox", defaultOnCopy: false, },
				public_name: {type: "text", visible: "publicly_visible", showOnTable: true, },
				public_description: {type: "text", visible: "publicly_visible", },
				public_price_range: {type: "text", visible: "publicly_visible", },
				capacity_per_hour: {type: "number", inputProps: {min: 1, max: 99999, step: 1}, required: true, default: 60, visible: "publicly_visible", },
				pdf_public_uri: { type: "file", filetypes: [".pdf"], required: false, visible: "publicly_visible", },
				terms_and_conditions_uri: { type: "file", filetypes: [".pdf"], required: false, visible: "publicly_visible", },
			},
		},
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: MenuBookIcon,
		// 	fields: {
		//
		// 	},
		// },
		
		// {
		// 	title: "GENERAL_INFO",
		// 	icon: MenuBookIcon,
		// 	fields: {
		// 		// text: {type: "text", required: true},
		// 		// textarea: {type: "text", multiline: true, rows: 12, required: true},
		// 		// number: {type: "number", required: true, inputProps: {min: 0, max: 99999, step: 1}},
		// 		// select: {type: "select", required: true, options: ["OPTION"]},
				
		// 		// md12: {type: "text", md: 12},
		// 		// showOnTable: {type: "text", showOnTable: false},
		// 	},
		// },

	];

	// PANDORA_ANCHOR_INSERT_OVERRIDE_FUNCTIONS_HERE
	static getCopyableChildren() { return [MenuItem]; };
}

export default Menu;