/*eslint-disable*/
import React, { Suspense } from "react";
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from "react-intl";
import { injectIntlModule } from "i18n/I18n";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";
import { hasLicense, hasPermission, uppercaseFirstLetterLowercaseRest } from "utils.js";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";

// material-ui icons
import Icon from "@material-ui/core/Icon";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import Person from "@material-ui/icons/Person";
import CompareArrows from "@material-ui/icons/CompareArrows";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	sidebarWrapper = React.createRef();
	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.sidebarWrapper.current, {
				suppressScrollX: true,
				suppressScrollY: false
			});
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
	}
	render() {
		const { className, user, headerLinks, links } = this.props;
		return (
			<div className={className} ref={this.sidebarWrapper}>
				{user}
				{headerLinks}
				{links}
			</div>
		);
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			openAvatar: false,
			miniActive: true,
			...this.getCollapseStates(props.routes)
		};
	}
	mainPanel = React.createRef();
	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates = routes => {
		let initialState = {};
		routes.map(prop => {
			if (prop.collapse || prop.mdl == "App\\CustomFrontendPageGroup") {
				initialState = {
					[prop.state ?? prop.name+"Collapse"]: this.getCollapseInitialState(prop.views ?? prop.custom_frontendables),
					...this.getCollapseStates(prop.views ?? prop.custom_frontendables),
					...initialState
				};
			}
			return null;
		});
		return initialState;
	};
	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if ((routes[i].collapse || routes[i].mdl == "App\\CustomFrontendPageGroup") && this.getCollapseInitialState(routes[i].views ?? routes[i].custom_frontendables)) {
				return true;
			} else if (window.location.href.indexOf(routes[i].path ?? routes[i].name) !== -1) {
				return true;
			}
		}
		return false;
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute = routeName => {
		const index = window.location.href.indexOf(routeName);
		const nextChar = index > -1 ? window.location.href.charAt(index + routeName.length) : null;
		return (index > -1 && (nextChar == "" || nextChar == "/" || nextChar == "\\" || nextChar == "?" || nextChar == "#")) ? "active" : "";
	};
	openCollapse(collapse) {
		var st = {};
		st[collapse] = !this.state[collapse];
		this.setState(st);
	}
	// this function creates the links and collapses that appear in the sidebar (left menu)
	createLinks = routes => {
		const { classes, color, rtlActive, intl, authEmployee, navbarNotifications } = this.props;
		return routes.filter(function(route) {
			return (typeof route.hidden !== 'undefined' ? !route.hidden : true);
		}).filter(function(route) {
			return (typeof route.permissions == 'undefined' || route.permissions == null || route.permissions == "" || route.permissions.length == 0 || hasPermission(authEmployee, route.permissions));
		}).filter(function(route) {
			return (typeof route.license == 'undefined' || hasLicense(authEmployee, route.license));
		}).sort(function (a, b) {
			return ((a.sortFirst ?? 0) == (b.sortFirst ?? 0)) ? (intl.formatMessage({id: "ROUTES."+a.name, defaultMessage: a.name}) > intl.formatMessage({id: "ROUTES."+b.name, defaultMessage: b.name}) ? 1 : -1) : ((a.sortFirst ?? 0) < (b.sortFirst ?? 0) ? 1 : -1);
		}).map((prop, key) => {
			if (prop.redirect) {
				return null;
			}
			if (prop.collapse || prop.mdl == "App\\CustomFrontendPageGroup") {
				prop["state"] = prop["state"] ?? prop.name+"Collapse";
				var st = {};
				st[prop["state"]] = !this.state[prop.state];
				const navLinkClasses =
					classes.itemLink +
					" " +
					cx({
						[" " + classes.collapseActive]: this.getCollapseInitialState(
							prop.views ?? prop.custom_frontendables
						)
					});
				const itemText =
					classes.itemText +
					" " +
					cx({
						[classes.itemTextMini]:
							this.props.miniActive && this.state.miniActive,
						[classes.itemTextMiniRTL]:
							rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.itemTextRTL]: rtlActive
					});
				const collapseItemText =
					classes.collapseItemText +
					" " +
					cx({
						[classes.collapseItemTextMini]:
							this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextMiniRTL]:
							rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextRTL]: rtlActive
					});
				const itemIcon =
					classes.itemIcon +
					" " +
					cx({
						[classes.itemIconRTL]: rtlActive
					}) +
					cx({
						[classes.itemIconCollapse]: (prop.collapse || prop.mdl == "App\\CustomFrontendPageGroup")
					});
				const caret =
					classes.caret +
					" " +
					cx({
						[classes.caretRTL]: rtlActive
					});
				const collapseItemMini =
					classes.collapseItemMini +
					" " +
					cx({
						[classes.collapseItemMiniRTL]: rtlActive
					});
				const navbarNotification = navbarNotifications.filter(navbarNotification => navbarNotification.name.toUpperCase()===prop.name.toUpperCase())[0];
				const subLinks = this.createLinks(prop.views ?? prop.custom_frontendables);
				if (Array.isArray(subLinks) && subLinks.length == 0) {
					return null; //Don't show empty collapses
				}
				return (
					<ListItem
						key={key}
						className={cx(
							{ [classes.item]: prop.icon !== undefined || prop.fa_icon !== undefined },
							{ [classes.collapseItem]: prop.icon === undefined || prop.fa_icon !== undefined }
						)}
					>
						<Suspense fallback={<div>Loading...</div>}>
							<NavLink
								to={"#"}
								className={navLinkClasses}
								onClick={e => {
									e.preventDefault();
									this.setState(st);
								}}
							>
								{prop.icon !== undefined ? (
									typeof prop.icon === "string" ? (
										<Icon className={itemIcon}>{prop.icon}</Icon>
									) : (
										<prop.icon className={itemIcon} />
									)
								) : (
									prop.fa_icon !== undefined ? (
										<i className={itemIcon+" "+prop.fa_icon}/>
									) : (
										<span className={collapseItemMini}>
											{rtlActive ? prop.rtlMini : prop.mini}
										</span>
									)
								)}
								{navbarNotification && navbarNotification.amount != 0 ?
									<span className={classes["notifications"+uppercaseFirstLetterLowercaseRest(navbarNotification.type)]}>{navbarNotification.amount}</span>
								: null}
								<ListItemText
									primary={intl.formatMessage({id: "ROUTES."+prop.name, defaultMessage: prop.name})}
									secondary={
										<b
											className={
												caret +
												" " +
												(this.state[prop.state] ? classes.caretActive : "")
											}
										/>
									}
									disableTypography={true}
									className={cx(
										{ [itemText]: prop.icon !== undefined || prop.fa_icon !== undefined },
										{ [collapseItemText]: prop.icon === undefined || prop.fa_icon !== undefined }
									)}
								/>
							</NavLink>
						</Suspense>
						<Collapse in={this.state[prop.state]} unmountOnExit>
							<List className={classes.list + " " + classes.collapseList}>
								{subLinks}
							</List>
						</Collapse>
					</ListItem>
				);
			}
			const innerNavLinkClasses =
				classes.collapseItemLink +
				" " +
				cx({
					[" " + classes[color]]: this.activeRoute(prop.path ?? encodeURIComponent(prop.name))
				});
			const collapseItemMini =
				classes.collapseItemMini +
				" " +
				cx({
					[classes.collapseItemMiniRTL]: rtlActive
				});
			const navLinkClasses =
				classes.itemLink +
				" " +
				cx({
					[" " + classes[color]]: this.activeRoute(prop.path ?? encodeURIComponent(prop.name))
				});
			const itemText =
				classes.itemText +
				" " +
				cx({
					[classes.itemTextMini]:
						this.props.miniActive && this.state.miniActive,
					[classes.itemTextMiniRTL]:
						rtlActive && this.props.miniActive && this.state.miniActive,
					[classes.itemTextRTL]: rtlActive
				});
			const collapseItemText =
				classes.collapseItemText +
				" " +
				cx({
					[classes.collapseItemTextMini]:
						this.props.miniActive && this.state.miniActive,
					[classes.collapseItemTextMiniRTL]:
						rtlActive && this.props.miniActive && this.state.miniActive,
					[classes.collapseItemTextRTL]: rtlActive
				});
			const navbarNotification = navbarNotifications.filter(navbarNotification => navbarNotification.name.toUpperCase()===prop.name.toUpperCase())[0];
			const itemIcon =
				classes.itemIcon +
				" " +
				cx({
					[classes.itemIconRTL]: rtlActive
				});
			return (
				<ListItem
					key={key}
					className={cx(
						{ [classes.item]: prop.icon !== undefined || prop.fa_icon !== undefined },
						{ [classes.collapseItem]: prop.icon === undefined || prop.fa_icon !== undefined }
					)}
				>
					<Suspense fallback={<div>Loading...</div>}>
						<NavLink
							to={(prop.layout ?? "/admin") + (prop.path ?? "/custom/" + prop.name + "/?company_id=" + authEmployee.company_id)}
							className={cx(
								{ [navLinkClasses]: prop.icon !== undefined || prop.fa_icon !== undefined },
								{ [innerNavLinkClasses]: prop.icon === undefined || prop.fa_icon !== undefined }
							)}
						>
							{prop.icon !== undefined ? (
								typeof prop.icon === "string" ? (
									<Icon className={itemIcon}>{prop.icon}</Icon>
								) : (
									<prop.icon className={itemIcon} />
								)
							) : (
								prop.fa_icon !== undefined ? (
									<i className={itemIcon+" "+prop.fa_icon}/>
								) : (
									<span className={collapseItemMini}>
										{rtlActive ? prop.rtlMini : prop.mini}
									</span>
								)
							)}
							{navbarNotification && navbarNotification.amount != 0 ?
									<span className={classes["notifications"+uppercaseFirstLetterLowercaseRest(navbarNotification.type)]}>{navbarNotification.amount}</span>
								: null}
							<ListItemText
								primary={intl.formatMessage({id: "ROUTES."+prop.name, defaultMessage: prop.name})}
								disableTypography={true}
								className={cx(
									{ [itemText]: prop.icon !== undefined || prop.fa_icon !== undefined },
									{ [collapseItemText]: prop.icon === undefined || prop.fa_icon !== undefined }
								)}
							/>
						</NavLink>
					</Suspense>
				</ListItem>
			);
		});
	};
	render() {
		const {
			classes,
			logo,
			image,
			logoText,
			routes,
			bgColor,
			rtlActive,
			authEmployee,
			intl
		} = this.props;
		const itemText =
			classes.itemText +
			" " +
			cx({
				[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
				[classes.itemTextMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.itemTextRTL]: rtlActive
			});
		const collapseItemText =
			classes.collapseItemText +
			" " +
			cx({
				[classes.collapseItemTextMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextRTL]: rtlActive
			});
		const userWrapperClass =
			classes.user +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white"
			});
		const caret =
			classes.caret +
			" " +
			cx({
				[classes.caretRTL]: rtlActive
			});
		const collapseItemMini =
			classes.collapseItemMini +
			" " +
			cx({
				[classes.collapseItemMiniRTL]: rtlActive
			});
		const photo =
			classes.photo +
			" " +
			cx({
				[classes.photoRTL]: rtlActive
			});
		const otherCompanies = this.props.authEmployee.user.employee_ids.map((company) =>
			<ListItem key={company.id} className={classes.collapseItem}>
				<NavLink
					to={"/auth/switch/"+company.id} /* Employee_id !!*/
					className={
						classes.itemLink + " " + classes.userCollapseLinks
					}
				>
					<span className={collapseItemMini}>
						<CompareArrows />
					</span>
					<ListItemText
						primary={company.company.name+(company.company.legal_name ? " ("+company.company.legal_name+")" : "")}
						disableTypography={true}
						className={collapseItemText}
					/>
				</NavLink>
			</ListItem>
		);
		var user = (
			<div className={userWrapperClass}>
				{/* <div className={photo}>
					<img src={avatar} className={classes.avatarImg} alt="..." />
				</div> */}
				<List className={classes.list}>
					<ListItem className={classes.item + " " + classes.userItem}>
						<NavLink
							to={"#"}
							className={classes.itemLink + " " + classes.userCollapseButton}
							onClick={() => this.openCollapse("openAvatar")}
						>
							<ListItemText
								primary={<span>{this.props.authEmployee.first_name+" "+this.props.authEmployee.last_name}<br /><i>{this.props.authEmployee.company.name+(this.props.authEmployee.company.legal_name ? " ("+this.props.authEmployee.company.legal_name+")" : "")}</i></span>}
								secondary={
									<b
										className={
											caret +
											" " +
											classes.userCaret +
											" " +
											(this.state.openAvatar ? classes.caretActive : "")
										}
									/>
								}
								disableTypography={true}
								className={itemText + " " + classes.userItemText}
							/>
						</NavLink>
						<Collapse in={this.state.openAvatar} unmountOnExit>
							<List className={classes.list + " " + classes.collapseList}>
								
								{otherCompanies}
								
								<hr></hr>
								<ListItem className={classes.collapseItem}>
									<NavLink
										to="/admin/my-profile"
										className={
											classes.itemLink + " " + classes.userCollapseLinks
										}
									>
										<span className={collapseItemMini}>
											<Person />
										</span>
										<ListItemText
											primary={intl.formatMessage({id: "ROUTES.PROFILE"})}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>
								<ListItem className={classes.collapseItem}>
									<NavLink
										to="/auth/logout-page"
										className={
											classes.itemLink + " " + classes.userCollapseLinks
										}
									>
										<span className={collapseItemMini}>
											<PowerSettingsNew />
										</span>
										<ListItemText
											primary={intl.formatMessage({id: "ROUTES.LOG_OUT"})}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>
							</List>
						</Collapse>
					</ListItem>
				</List>
			</div>
		);
		var links = (
			<List className={classes.list}>{this.createLinks(routes)}</List>
		);

		const logoNormal =
			classes.logoNormal +
			" " +
			cx({
				[classes.logoNormalSidebarMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.logoNormalSidebarMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.logoNormalRTL]: rtlActive
			});
		const logoMini =
			classes.logoMini +
			" " +
			cx({
				[classes.logoMiniRTL]: rtlActive
			});
		const logoClasses =
			classes.logo +
			" " +
			cx({
				[classes.whiteAfter]: bgColor === "white"
			});
		var brand = (
			<div className={logoClasses}>
				<a
					// href="https://www.creative-tim.com?ref=mdpr-sidebar"
					target="_blank"
					className={logoMini}
				>
					<img src={logo} alt="logo" className={classes.img} />
				</a>
				<a
					// href="https://www.creative-tim.com?ref=mdpr-sidebar"
					target="_blank"
					className={logoNormal}
				>
					{logoText}
				</a>
			</div>
		);
		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.drawerPaperRTL]: rtlActive
			});
		const sidebarWrapper =
			classes.sidebarWrapper +
			" " +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.sidebarWrapperWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1,
				[classes.sidebarWrapperForIOS]:
					(navigator.platform.indexOf("iPhone") > -1 || navigator.platform.indexOf("iPod") > -1 || navigator.platform.indexOf("iPad") > -1)
			});
		return (
			<div ref={this.mainPanel}>
				<Hidden mdUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={rtlActive ? "left" : "right"}
						open={this.props.open}
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"]
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							headerLinks={<AdminNavbarLinks rtlActive={rtlActive} />}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						onMouseOver={() => this.setState({ miniActive: false })}
						onMouseOut={() => this.setState({ miniActive: true })}
						anchor={rtlActive ? "right" : "left"}
						variant="permanent"
						open
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"]
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
					</Drawer>
				</Hidden>
			</div>
		);
	}
}

Sidebar.defaultProps = {
	bgColor: "blue"
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([
		"white",
		"red",
		"orange",
		"green",
		"blue",
		"purple",
		"rose"
	]),
	logo: PropTypes.string,
	logoText: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	miniActive: PropTypes.bool,
	open: PropTypes.bool,
	handleDrawerToggle: PropTypes.func
};

SidebarWrapper.propTypes = {
	className: PropTypes.string,
	user: PropTypes.object,
	headerLinks: PropTypes.object,
	links: PropTypes.object
};

function mapStateToProps(state) {
	return {
		authEmployee: state.auth.authEmployee ?? null,
		navbarNotifications: state.notifications?.navbar ?? [],
	}
}

export default injectIntlModule(null, injectIntl(withStyles(sidebarStyle)(connect(mapStateToProps)(Sidebar), { withTheme: true })));